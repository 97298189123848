import { Injectable } from '@angular/core';

@Injectable()
export class CameraService {

    deviceId = '';
    availableDevices: MediaDeviceInfo[] = [];
    audioContext = new AudioContext();

    scanError() { this.playSound(999, 220, 300); }

    scanOk() { this.playSound(100, 520, 200); }

    playSound(volume: number, frequency: number, duration: number) {
        const vibrator = this.audioContext.createOscillator();
        const u = this.audioContext.createGain();
        vibrator.connect(u);
        vibrator.frequency.value = frequency;
        vibrator.type = 'square';
        u.connect(this.audioContext.destination);
        u.gain.value = volume * 0.01;
        vibrator.start(this.audioContext.currentTime);
        vibrator.stop(this.audioContext.currentTime + duration * 0.001);
    }

}
