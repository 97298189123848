<div class="main-light-theme">
    <mat-toolbar class="mat-elevation-z6"
                 color="primary">
        <button mat-button
                routerLink="">
            АМБАР
        </button>
    </mat-toolbar>
    <div class="mdc-layout-grid">
        <div class="mdc-layout-grid__inner pt-1">
            <mat-card class="mdc-layout-grid__cell--span-12">
                <mat-card-title>
                    <div>Проверка на артикл</div>
                    <div class="align-middle mdc-layout-grid__cell--span-2-phone mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-6-desktop text-center">
                        <button (click)="openScanner()"
                                color="primary"
                                mat-fab>
                            <mat-icon>qr_code_scanner</mat-icon>
                        </button>
                    </div>
                </mat-card-title>
                <mat-card-content>
                    <div class="mdc-layout-grid__inner">
                        <form (keydown.enter)="postStavka()"
                              [formGroup]="stavkaForma"
                              class="mdc-layout-grid__cell--span-12"
                              novalidate>
                            <div class="mdc-layout-grid__inner">
                                <mat-form-field class="mdc-layout-grid__cell--span-12">
                                    <input #artiklInput
                                           autocomplete="false"
                                           formControlName="artikl"
                                           matInput
                                           placeholder="Баркод">
                                </mat-form-field>
                            </div>
                        </form>
                        <div *ngIf="proverka"
                             class="mdc-layout-grid__cell--span-12">
                            <div class="mdc-layout-grid__inner">
                                <h3 class="mdc-layout-grid__cell--span-12">
                                    {{ proverka.naziv }} - {{ proverka.prodaznaCena | number: '1.0-0'}} ({{ proverka.originalnaCena | number: '1.0-0'}})
                                </h3>
                                <div class="mdc-layout-grid__cell--span-6">
                                    <table class="mat-table">
                                        <tr class="mat-header-row">
                                            <th class="mat-header-cell">
                                                Локација
                                            </th>
                                            <th class="mat-header-cell text-right">
                                                Залиха
                                            </th>
                                        </tr>
                                        <tr *ngFor="let stavka of proverka.zaliha"
                                            class="mat-row">
                                            <td class="align-middle mat-cell">
                                                {{ stavka.naziv }}
                                            </td>
                                            <td class="align-middle mat-cell text-right">
                                                {{ stavka.kolicina | number : '1.0-0' }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="mdc-layout-grid__cell--span-6 proverka text-center">
                                    <img *ngIf="proverka.imaSlika"
                                         [src]="image"
                                         class="d-block mx-auto rounded"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
