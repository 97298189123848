import { BarcodeFormat } from '@zxing/library';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CameraService } from '../../core/camera.service';

@Component({
    templateUrl: './barcode-scanner-dialog.component.html'
})
export class BarcodeScannerDialogComponent {

    forma: FormGroup;
    allowedFormats = [BarcodeFormat.CODE_128];
    currentDevice: MediaDeviceInfo = null;
    er = '';
    constructor(public formBuilder: FormBuilder,
        public cameraService: CameraService,
        public dialogRef: MatDialogRef<BarcodeScannerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        if (this.cameraService.deviceId !== '') {
            this.currentDevice =
                this.cameraService.availableDevices.find(x => x.deviceId === this.cameraService.deviceId);
        }
        this.forma = this.formBuilder.group({
            camera: new FormControl(this.currentDevice, { validators: [Validators.required] }),
        });
    }

    onCamerasFound(devices: MediaDeviceInfo[]): void {
        this.er += devices;
        if (this.cameraService.availableDevices.length !== devices.length) {
            this.cameraService.availableDevices = devices;
        }
    }

    onDeviceSelectChange(event: MatSelectChange) {
        this.currentDevice = event.value;
        this.cameraService.deviceId = this.currentDevice.deviceId;
    }

    onCodeResult(event) { this.dialogRef.close(event); }

}
