import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMessageDialog } from '../../models/message-dialog';

@Component({
    templateUrl: './message-dialog.component.html'
})
export class MessageDialogComponent {

    constructor(public dialogRef: MatDialogRef<MessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IMessageDialog) {
    }

    yes() { this.dialogRef.close(true); }

    no() { this.dialogRef.close(false); }

}
