import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { COMPONENT_DECLARATIONS } from './components/component-declarations';
import { DIALOG_DECLARATIONS } from './dialogs/dialog-declarations';
import { MaterialDesignModule } from './material-design.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        MaterialDesignModule,
        ZXingScannerModule
    ],
    declarations: [
        COMPONENT_DECLARATIONS,
        DIALOG_DECLARATIONS
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        MaterialDesignModule,
        COMPONENT_DECLARATIONS,
        DIALOG_DECLARATIONS,
        ZXingScannerModule
    ],
})
export class SharedModule {
}
