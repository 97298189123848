import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IProverkaNaArtikl } from '../models/proverka-na-artikl';

@Injectable()
export class IzvestaiService {

    constructor(private http: HttpClient) {}
    
    getProverka(barkod: string): Observable<IProverkaNaArtikl> {
        return this.http.get<IProverkaNaArtikl>(`/IzvestaiApi/Proverka/Get/${barkod}`);
    }

}
