
// ANGULAR
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// APP MODULES
import { AppRoutingModule } from './app-routing/app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

// COMPONENTS AND DIALOGS
import { APP_DIALOG_DECLARATIONS } from './dialogs/app-dialog.declarations';

// PAGES
import { AppComponent } from './app.component';


@NgModule({
    declarations: [
        AppComponent,
        APP_DIALOG_DECLARATIONS
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        BrowserAnimationsModule,
        SharedModule,
        CoreModule,
        AppRoutingModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
