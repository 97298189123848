<div class="mdc-layout-grid">
    <div class="mdc-layout-grid__inner">
        <form (keydown.enter)="$event.preventDefault()"
              [formGroup]="forma"
              class="mdc-layout-grid__cell--span-12"
              novalidate>
            <div class="mdc-layout-grid__inner">
                <mat-form-field class="mdc-layout-grid__cell--span-12">
                    <mat-select (selectionChange)="onDeviceSelectChange($event)"
                                formControlName="camera">
                        <mat-option *ngFor="let device of cameraService.availableDevices"
                                    [value]="device">
                            {{ device.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
        <zxing-scanner (camerasFound)="onCamerasFound($event)"
                       (scanSuccess)="onCodeResult($event)"
                       [(device)]="currentDevice"
                       [formats]="allowedFormats"
                       class="mdc-layout-grid__cell--span-12">
        </zxing-scanner>
    </div>
</div>
