<div matDialogTitle>Ве молиме почекајте</div>
<div mat-dialog-content>
    <div class="mdc-layout-grid">
        <div class="mdc-layout-grid__inner">
            <div align="center"
                 class="mdc-layout-grid__cell--span-12">
                <mat-spinner mode="indeterminate"></mat-spinner>
            </div>
        </div>
    </div>
</div>
