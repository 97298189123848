import { Component, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { IzvestaiService } from './core/izvestai.service';
import { MessageDialogService } from './core/message-dialog.service';
import { IProverkaNaArtikl } from './models/proverka-na-artikl';
import { BarcodeScannerDialogComponent } from './dialogs/barcode-scanner-dialog/barcode-scanner-dialog.component';
import { CameraService } from './core/camera.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    proverka: IProverkaNaArtikl;
    stavkaForma: FormGroup;

    @ViewChild('artiklInput', { read: MatInput })
    artiklInput: MatInput;

    image: any;

    constructor(public messageDialogService: MessageDialogService,
        private sanitizer: DomSanitizer,
        public overlayContainer: OverlayContainer,
        public cameraService: CameraService,
        public dialogService: MatDialog,
        public formBuilder: FormBuilder,
        public izvestaiService: IzvestaiService) {
        this.createForma();
        this.overlayContainer.getContainerElement().classList.add('main-light-theme');
    }

    ngAfterViewInit() { setTimeout(() => { this.artiklInput.focus(); }); }

    createForma() {
        this.stavkaForma = this.formBuilder.group({
            artikl: new FormControl('', { validators: [Validators.required] }),
        });
    }

    postStavka() {
        this.messageDialogService.showSpinner();
        this.izvestaiService.getProverka(this.stavkaForma.value.artikl).subscribe(result => {
                this.proverka = result;
                if (this.proverka.imaSlika) {
                    this.image = this.sanitizer.bypassSecurityTrustUrl(`data:image/jpeg;base64,${result.slika}`);
                }
                this.messageDialogService.hideSpinner();
            },
            () => {
                this.messageDialogService.showMessage('Артиклот не постои');
                this.artiklInput.focus();
                this.messageDialogService.hideSpinner();
            });
    }

    openScanner() {
        this.dialogService.open(BarcodeScannerDialogComponent, { minWidth: '90vw' }).afterClosed()
            .subscribe((result: string) => {
                if (result) {
                    this.cameraService.scanOk();
                    this.stavkaForma.patchValue({ artikl: result });
                    this.postStavka();
                }
            });
    }

}
