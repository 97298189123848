<div matDialogTitle>
    {{ data.message }}
</div>
<div align="end"
     mat-dialog-actions>
    <button (click)="yes();"
            (keydown.enter)="yes();"
            color="primary"
            mat-raised-button>
        <span *ngIf="!data.confirm">Во ред</span>
        <span *ngIf="data.confirm">Да</span>
    </button>
    <button (click)="no();"
            *ngIf="data.confirm"
            color="warn"
            mat-raised-button>
        Не
    </button>
</div>
